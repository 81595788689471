import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/common';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrl: './sub-header.component.scss'
})
export class SubHeaderComponent implements OnInit {
  isDropdownOpen = false;
  options: any;
  selectedOption: any;
  projectList: any;

  constructor(public commonService: CommonService,
    private userservice: UserService, private authService: AuthService) {

  }

  ngOnInit(): void {
    this.refreshProjectsInDropDown();
    this.observeSiteSwitch();
  }

  observeSiteSwitch() {
    this.commonService.refreshProject.subscribe((res: any) => {
      if (res)
        this.refreshProjectsInDropDown()
    })
  }

  refreshProjectsInDropDown() {
    this.projectList = this.commonService.getUserPojectListBySiteId(this.commonService.getCurrentSiteId())
    if (this.projectList) {
      this.setselectedProjectsInDropDown()
    } else {
      if (this.authService.loggedIn()) {
        // if user project list could not be found in  local storge then refresh it based on current site id and user Id
        this.userservice.getUserProjects(this.commonService.getLoggedInUserId()).subscribe(res => {
          if (res) {
            this.projectList = res?.result
            this.commonService.setLocalStorageValue("userProjects", JSON.stringify(this.projectList))
            this.projectList = this.commonService.getUserPojectListBySiteId(this.commonService.getCurrentSiteId())
            this.setselectedProjectsInDropDown()
          }
        })
      }
    }
  }

  setselectedProjectsInDropDown() {
    this.options = this.projectList;
    this.selectedOption = this.options[0]?.id;
    this.commonService.updateSelectedProjects(this.projectList[0])
  }

  //toggle used to open the dropdown
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  /**
   * Change the selected project in sub header and refresh data for new project 
   */
  changeProject() {
    if (this.authService.loggedIn()) {
      var projectData = this.options.find((op: any) => op.id == this.selectedOption);
      localStorage.setItem('selectedProject', JSON.stringify(projectData).replace("projectId", "id"));
      this.commonService?.updateSelectedProjects(projectData);
    }
  }
}
