import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";
import { BehaviorSubject } from "rxjs";
import { CommonService } from "./common.service";
import { environment } from "../../../environments/environment";


@Injectable()
export class UserService {
    dummyDataUrl: string = '../../../assets/dummydb/';
    public isCustomerData: boolean = false;
    private customerDetails = new BehaviorSubject<any>(null);

    //Contains information about currently selected user
    public currentUserData = new BehaviorSubject<any>(null);

    constructor(private restService: RestService, private commonService: CommonService) { }

    getUserData() {
        return this.currentUserData.asObservable();
    }

    setUserData(updatedData: any) {
        this.currentUserData.next(updatedData);
    }

    /**
   * Fetches the booking payment details
   * @param siteId (optional) site Id for which user's details needs to be fetched
   * @param projectId (optional) project Id for which user's details needs to be fetched
   * @param userSubTypeId (optional) user Sub Type Id for which user's details needs to be fetched
   * @param userId (optional) customer Id for which user's details needs to be fetched
   * @returns returns the details of user
   */
    getFilterUser(siteId: any = null, projectId: any = null, subTypeId: any = null, userId: any = null) {
        let localUrl: any = this.commonService.setDataUrl('user', environment.dummyDbPath + 'user.json');
        //Check If any of the filter is applied
        if (siteId || projectId || subTypeId || userId) localUrl += '?'
        //Set all filters params if available
        localUrl = siteId ? localUrl + 'siteId=' + siteId : localUrl
        localUrl = projectId ? (siteId ? localUrl + '&projectId=' + projectId : localUrl + 'projectId=' + projectId) : localUrl
        localUrl = subTypeId ? ((siteId || projectId) ? localUrl + '&subTypeId=' + subTypeId : localUrl + 'subTypeId=' + subTypeId) : localUrl
        localUrl = userId ? ((siteId || projectId || subTypeId) ? localUrl + '&id=' + userId : localUrl + 'id=' + userId) : localUrl
        return this.restService.getData(localUrl);
    }


    /**
     * Get Bank Data
     * @returns Get API
     */
    getBankData() {
        return this.restService.getData('Bank/')
    }

    /**
     * Post Bank Data
     * @returns Post API
     */
    postBankData(data: any) {
        return this.restService.postData('bank-master/bankaccounts', data)
    }


    /**
     * Update Bank Data
     * @returns Update API
     */
    updateBankData(id: any, data: any) {
        return this.restService.updateData('bank-master/bankaccounts', id, data)
    }

    // Customer Data Refresh As Observable Through
    getCustomerDetail() {
        return this.customerDetails?.asObservable()
    }

    setCustomerDetails(data: any) {
        return this.customerDetails?.next(data);
    }

    getBloodGroupList() {
        return this.commonService.setDataUrl('bloodGroup', environment.dummyDbPath + 'bloodGroup.json');
    }


    /**
     * Gets user's sites based on user id
     * @param userId User whose sites has to be fetched
     * @returns returns user's sites
     */
    getUserSItes(userId: any) {
        let localUrl: any = this.commonService.setDataUrl('site?userId=' + userId, environment.dummyDbPath + 'userSite.json');
        return this.restService.getData(localUrl);
    }

    /**
     * Gets user's projects based on user id
     * @param userId User whose projects has to be fetched
     * @returns returns user's projects
     */
    getUserProjects(userId: any) {
        let localUrl: any = this.commonService.setDataUrl('project?userId=' + userId, environment.dummyDbPath + 'userProject.json');
        return this.restService.getData(localUrl);
    }

    updateUser(id: any, data: any) {
        return this.restService.updateData('user', id, data)
    }

}