export const environment = {
  production: false,
  env: "dev",
  apiEndpoint: 'https://devapi.crm.manubhoomi.info',

  //set if current API DB is dummy or not
  isDummyDataSource: false,
  dummyDbPath: "assets/dummydb/",

  decumentVerificationSource: 'deepvue',
  deepVueAPIUrl: 'https://production.deepvue.tech/v1/',
  assetsImagePath: "assets/images/"
};