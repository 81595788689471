import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',

})

export class HeaderComponent implements OnInit {
  @Output() expanded: boolean = true;
  menu!: boolean
  constructor(public commonService: CommonService, private authService: AuthService) {

  }

  ngOnInit(): void {
    //get current site of user. If he has already selected any particular site then get it from local storage
    //if not then check if he has access to multiple sites or single. In case of single site access he will have 
    //no option to select site, but if he has access to multiple sites then he will see switch site modal
    // here the objective to call this method is just to set the current id id in local storage 
    this.commonService.getCurrentSiteId()
  }

  //for left nav hide and show
  toggleSidebar() {
    this.commonService.isHidden = !this.commonService.isHidden;
  }

  /**
   * Selected Site Switch
   */
  showSwitchSite() {
    this.commonService?.showSiteModal.next(true);
  }

  /**
   * Click Logout Button And All Data Remove localStorage
   */
  logout() {
    this.authService.logout();
    this.commonService.localStorageSiteId = undefined;
    this.commonService.updateSelectedProjects(null);
  }
}