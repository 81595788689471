import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestService } from './rest.service';
import { AuthService } from '../../auth/auth.service';
import { Base64FileFormats } from '../../../appconfig';
import { environment } from '../../../environments/environment';
import { formatDate } from '@angular/common';
const userAgent: string = navigator.userAgent;


const isMobileDevice = (): boolean => {
  const regexs = [
    /(Android)(.+)(Mobile)/i,
    /BlackBerry/i,
    /iPhone|iPod/i,
    /Opera Mini/i,
    /IEMobile/i,
  ];
  return regexs.some((b) => userAgent.match(b));
};

const isTabletDevice = (): boolean => {
  const regex =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
  return regex.test(userAgent.toLowerCase());
};

const isDesktopDevice = (): boolean => !isMobileDevice() && !isTabletDevice();
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  nameInitial: string = '';
  relationInitial: string = '';
  selectedTab: number = 0;
  address: string = '';
  dummyDataUrl: string = '../../../assets/dummydb/';
  public isMobileDevice: boolean = isMobileDevice();
  public userProfile = new BehaviorSubject<any>(null);
  public actionType = new BehaviorSubject<any>(null);


  constructor(
    private toastr: ToastrService,
    private restService: RestService,
    private authService: AuthService,
    private datepipe: DatePipe
  ) {
    if (isDesktopDevice()) {
      this.isHidden = false;
    }
    if (isMobileDevice() || isTabletDevice()) {
      this.isHidden = true;
    }
  }

  public isHidden: boolean = false;
  public siteId = new BehaviorSubject<any>(null);
  public selectedOption = new BehaviorSubject<any>(null);
  public showSiteModal = new BehaviorSubject<any>(false);


  public refreshProject = new BehaviorSubject<any>(null);
  private selectedProjectsSubject = new BehaviorSubject<any>(null);
  public selectedProjects$ = this.selectedProjectsSubject.asObservable();
  private selectedBookingId: any;
  public localStorageSiteId: any = localStorage.getItem('siteId');


  //#region  SITE AND PROJECTS
  /**
   * Gets the current site Id from local storage
   */
  getCurrentSiteId() {
    // First read current site id from local storage
    var selectedSiteId = this.getValueFromLocalStorage("siteId")
    //if we found the current site id then retrurn it
    if (selectedSiteId) return selectedSiteId

    //if selected site is not available then  we need to get it selected from userSites (all sites of uers)
    //here we need to check if usersSite available in local storage or not. if available then read from local storage
    //otherwise get user's sites from API based on current logged in userId
    var userSites: any = this.getValueFromLocalStorage("userSites")


    //check if user sites are available or not in local storage. If not then get it from API
    if (userSites) {
      userSites = JSON.parse(userSites)
      if (userSites.length > 1) {
        this.openSiteModal();
      }
      else {
        //if there is only one site in userSites list then that will be set as default siteId
        this.setLocalStorageValue("siteId", userSites[0]?.siteId)
      }
    }
    else {
      this.getUserSitesFromAPI(this.getLoggedInUserId())
    }

    //by now user must have selected the current site id from modal
    var selectedSiteId = this.getValueFromLocalStorage("siteId")
    return selectedSiteId
  }


  /**
   * getting sites on behalf of userId from API
   * @param userId on behalf of which sites has to be fetched
   */
  getUserSitesFromAPI(userId: any) {
    if (userId) {
      //here we setting values in local storage because here we getting data from api and we need to wait to receive the data
      this.restService.getData('site?userId=' + userId).subscribe((data: any) => {
        localStorage.setItem('userSites', JSON.stringify(data?.result));
        if (data?.result?.length > 1) {
          this.openSiteModal()
        }
        else {
          this.setLocalStorageValue("siteId", JSON.stringify(data?.result[0]))
        }
      });
    }
  }


  /**
   * Gets users Project List by Site Id from API
   * @param siteId Site Id for which project list ahs to be fetched
   */
  getUserPojectListBySiteId(siteId: any) {
    //First get user project list from Local storage and return if found.
    var userProjetList;
    userProjetList = this.getValueFromLocalStorage("userProjects")
    if (userProjetList) {
      userProjetList = userProjetList.replaceAll("projectId", "id")
      userProjetList = JSON.parse(userProjetList)
      var selectedSiteProjects = userProjetList.filter((x: any) => x.siteId == siteId)
      this.setLocalStorageValue("selectedProject", JSON.stringify(selectedSiteProjects[0]))
      return (selectedSiteProjects != null) ? selectedSiteProjects : null
    }
  }


  /**
   * return selected proejctId from localstorage
   * @returns curent selected project
   */
  getProjectFromLocalStorage(): any {
    let projectData: any;
    let project: any;
    //Read project information from local storage
    projectData = localStorage.getItem('selectedProject');
    if (projectData) {
      //parse the project information into json format
      project = JSON.parse(projectData);
      return project;
    } else {
      //refil project information based on user site. Emit this to subheader projectList()
      return this.setUserProjects();

      // return this.getProjectFromLocalStorage();
    }
  }




  /**
   * getting user project on behalf of siteId and userId
   * @param siteId getting projects on behalf of siteId comes from local storage
   * @param userId getting projects on behalf of userId comes from userToken
   * @returns
   */
  getUserProjectsFromAPI(siteId: any, userId: any) {
    var userProjects;

    if (!siteId) {
      return;
    }
    this.restService.getData('project/?userId=' + userId)
      .subscribe((data: any) => {
        if (data.result) {
          localStorage.setItem('userProjects', JSON.stringify(data?.result));
          userProjects = data?.result.filter((x: any) => x.siteId == this.getCurrentSiteId())
          if (userProjects) {
            localStorage.setItem('selectedProject', JSON.stringify(userProjects[0]))
            this.selectedProjectsSubject.next(userProjects[0]);
            return userProjects;
          }
        }
      });
  }

  //Sets the user project list
  setUserProjects() {
    // if (!this.isProjectApiCalled) {
    this.getUserProjectsFromAPI(
      this.getCurrentSiteId(),
      this.getValueFromLocalStorage("userId")
    );
    // }
  }

  //#endregion




  /**
   * defined the selected booking Id
   * @param bookingId
   */
  setSelectedBookingId(bookingId: any) {
    this.selectedBookingId = bookingId;
  }


  /**
   *
   * @returns exporting the selected row ID of the grid
   */
  getSelectedRowId(): any {
    return this.selectedBookingId;
  }

  /**
   * success toaster message
   * @param message
   */
  showSuccess(message: string) {
    this.toastr.success(message);
  }

  /**
   * warning toaster message
   * @param message
   */
  showWarning(message: string) {
    this.toastr.warning(message);
  }

  showError(message: string) {
    this.toastr.error(message);
  }

  /**
   * converting image type string to base64
   * @param normalString
   * @returns
   */
  BaseStringToNormalString(normalString: any) {
    if (normalString)
      return Base64FileFormats.JPEG + normalString;
    else return null;
  }

  /**
   * method is used convert base64 to normal string
   * @param baseString
   * @returns
   */
  normalStringToBaseString(baseString: string) {
    if (baseString)
      return baseString.replace(/^data:image\/(jpeg|png|jpg);base64,/, '')
    else return null;
  }

  /**
   * returning the expected date format for exported file name and transactionId
   * @returns
   */
  getCurrentTimeStamp() {
    let pipe = new DatePipe('en-US');
    return pipe.transform(new Date(), 'MMddyyyyHHmmss');
  }

  /**
   * common search function for grid
   * @param gridApi
   * @param field
   */
  getCurrentTimeStampClient() {
    let pipe = new DatePipe('en-US');
    return pipe.transform(new Date(), 'MMddyyyyHHmm');
  }

  /**
   * common search function for grid
   * @param gridApi
   * @param field
   */
  searchText(gridApi: any, field: any) {
    gridApi.setGridOption('quickFilterText', field);
  }

  /**
   * Getting Initials
   */
  initialState() {
    this.restService.getData('name-initial?type=name').subscribe((res) => {
      this.nameInitial = res.result.name;
    });
  }

  /**
   * opening site modal while click Switch Site from profile dropdown
   */
  openSiteModal() {
    this.showSiteModal.next(true)
  }

  /**
   * Gets Current Logged in user
   */
  getLoggedInUserId() {
    var currentUserId = null;
    //First check id current user id available in local storage or not if not then checck if user is logged in or not
    currentUserId = this.getValueFromLocalStorage("userId")
    if (currentUserId) return currentUserId

    this.authService.loggedIn()
    return currentUserId
  }

  /**
   * Gets userId from token which  was stored in local storage after userlogin
   * @returns returns user id
   */
  getValueFromLocalStorage(key: any) {
    return localStorage.getItem(key);
  }

  /**
   * Sets value of a key in local storage
   * @param key key against which value has to be set
   * @param value values which has to be set in local storage
   */
  setLocalStorageValue(key: any, value: any) {
    localStorage.setItem(key, value)
  }

  /**
   * Method to set the selected tab index
   * @param index index of selected tab which is to be set
   */
  selectTab(index: number): void {
    this.selectedTab = index;
  }

  /**
   * Method to check if a tab is selected
   * @param index index of selected tab which is to be check is it selected or not
   * @returns
   */
  isTabSelected(index: number): boolean {
    return this.selectedTab === index;
  }

  /**
   * sets the dummy data url conditionally i.e. if API is working then data will come from actual api url else it will use dummy url for getting dummmy data
   * @param apiUrl actual api url
   * @param dummydataUrl dummy data url
   * @returns returns api url for data
   */
  setDataUrl(apiUrl: string, dummydataUrl: string) {
    if (environment.isDummyDataSource) return dummydataUrl
    else return apiUrl
  }

  /**
  * Gets the current user profile data
  */
  getUserProfile() {
    return this.userProfile.asObservable();
  }

  /**
   * Sets current selected booking object so that it can be subscribed in any component.
   * Right now using to show document of a particular booking
   * @param selectedBooking currently selected booking
   */
  setUserProfile(updatedData: any) {
    this.userProfile.next(updatedData);
  }

  //getting gender from dummy data
  getGenders(): any {
    return {
      'result': [{ 'name': 'Male' }, { 'name': 'Female' }]
    }
  }

  addSpaceInCamelCase(value: string) {
    return value.replace(/([A-Z])/g, ' $1').trim()
  }

  /**
   * put method to update user address
   * @param id userId on which behalf updating user's address
   * @param data payload
   * @returns
   */
  addressupdate(id:any,data: any) {
    return this.restService.updateData('user/addresses',id, data);
  }

  /**
   * Formats the address comma and hyphen puncuations (removes extra commas)
   * @param address Address to be formatted
   */
  formateAddress(address: string): string {
    // Replace the double comma with single comma in case if any part of address is missing like Address 2
    address = address.replace(",,", ",").replace(",-", "-")

    //If Pin number in address is not available then remove the hyphen in last from address field
    if (address.substring(address.length - 1, address.length) == "-")
      address = address.substring(0, address.length - 1)

    return address;
  }

  /**
   * Gets List of unique values objects from a json aray
   * @param arrayList Json array object from which quniue item list has to be filter
   * @param uniqueFieldToFind Key which has to be filtered uniquely
   */
  getUniqueValuesFromJsonArray(arrayList: any[], uniqueFieldToFind: any) {
    let uniqueSites: any[] = [];

    arrayList.forEach((currRecord: any) => {
      let isExists: boolean = false

      uniqueSites.forEach(uniquesite => {
        if (currRecord[uniqueFieldToFind] == uniquesite[uniqueFieldToFind])
          isExists = true;
      })
      if (!isExists) uniqueSites.push(currRecord)
    });

    return uniqueSites;
  }

  /**
   *
   * @param name
   * @returns
   */
  getUsersFirstLastName(name: string): { firstName: string; lastName: string } {
    const words = name.trim().split(" ");
    let firstName = "";
    let lastName = "";

    if (words.length === 1) {
      // If there's only one word, set it as the first name
      firstName = words[0];
    } else if (words.length === 2) {
      // If there are two words, split them into first and last name
      firstName = words[0];
      lastName = words[1];
    } else {
      // If there are more than two words, combine the first two as firstName and the rest as lastName
      firstName = words.slice(0, 2).join(" ");
      lastName = words.slice(2).join(" ");
    }

    return { firstName, lastName };
  }

  setActionType(value: any) {
    this.actionType.next(value);
  }

  getActionType() {
    return this.actionType.asObservable();
  }

  dateTimeFormate(date: Date) {
    return formatDate(date, 'yyyy-MM-ddTHH:mm:ss', 'en-US');
  }

  isNullUndefined(input: any): boolean {
    return input === "" || input === null || input === undefined;
  }

  /**
   *
   * @param value
   */
  updateSelectedProjects(value: any): void {
    this.selectedProjectsSubject.next(value);
  }

  getStates() {
    return this.restService.getData('Location/state?countryId=102', false);
  }

  getCity(id:any){
    return this.restService.getData('Location/city?stateId=' + id,false)
  }
}
